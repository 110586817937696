<template>
    <div class="statisticsInfo">
        <div class="facilityBox">
            <el-breadcrumb separator="/" style="margin:0 0 10px 0;">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>统计查看</el-breadcrumb-item>
                <el-breadcrumb-item>查看详情</el-breadcrumb-item>
            </el-breadcrumb>
            <el-row class="settleBox">
                <el-col :span="5">货主信息：<strong>{{ info.CompanyName }}</strong></el-col>
                <el-col :span="6">业务类型：<strong>{{ info.BusinessTypeName }}</strong></el-col>
                <el-col :span="4">票制：<strong>{{ info.InvoicingName }}</strong></el-col>
            </el-row>
            <!-- 搜索表单 -->
            <el-form ref="searchForm" inline :model="searchForm">
                <el-form-item label="运单状态：" prop="waybillStatus" label-width="100px">
                    <el-select @change="search" v-model="searchForm.waybillStatus" placeholder="请选择运单状态"
                        style="width: 180px">
                        <el-option label="全部" value="0"></el-option>
                        <el-option label="货主已申请结算" value="1"></el-option>
                        <el-option label="服务商已同意开票" value="2"></el-option>
                        <el-option label="货主已付款" value="3"></el-option>
                        <el-option label="服务商已开票" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日期：" prop="time" label-width="60px">
                    <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="至"
                        start-placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="开票申请编号：" prop="InvoiceTaskId" label-width="140px">
                    <el-input v-model="searchForm.InvoiceTaskId" placeholder="开票申请编号" style="width: 180px"></el-input>
                </el-form-item>
                <el-button type="primary" icon="el-icon-zoom-in" @click="search">搜索</el-button>
                <el-button icon="el-icon-delete" @click="$refs['searchForm'].resetFields()">清空</el-button>
            </el-form>
            <!-- 顶部按钮 -->
            <div class="topbtns">
                <el-button size="small" @click="$router.back()" icon="el-icon-arrow-left">返回</el-button>
                <el-button icon="el-icon-download" :disabled="!pagination.list.length" type="primary" size="medium"
                    @click="exportExcel">导出表格
                </el-button>
            </div>
            <!--数量金额总计-->
            <div class="settleBox">
                <div style="display:flex;">
                    <div class="sum-item"><span class="sum-txt">开票总金额：</span><span class="sum-num">{{
                            totalData.TotalAmountSum ? '￥' +
                                totalData.TotalAmountSum : '/'
                    }}</span></div>
                    <div class="sum-item" v-if="info.InvoicingName != '两票制'">
                        <span class="sum-txt">服务费/税费：</span><span class="sum-num">{{
                                totalData.ExpensesOfTaxationSum
                                    ? '￥'
                                    +
                                    totalData.ExpensesOfTaxationSum : '/'
                        }}</span>
                    </div>
                    <div class="sum-item" v-if="info.InvoicingName != '两票制'"><span class="sum-txt">运费：</span><span
                            class="sum-num">{{
                                    totalData.FreightSum ? '￥' +
                                        totalData.FreightSum : '/'
                            }}</span></div>

                    <div class="sum-item" v-if="info.InvoicingName == '两票制'"><span class="sum-txt">运费含税金额：</span><span
                            class="sum-num">{{
                                    totalData.WaybillTotalAmountSum ? '￥' +
                                        totalData.WaybillTotalAmountSum : '/'
                            }}</span></div>
                    <div class="sum-item" v-if="info.InvoicingName == '两票制'"><span
                            class="sum-txt">技术服务费含税金额：</span><span class="sum-num">{{
                                    totalData.TecServiceFeeSum ? '￥' +
                                        totalData.TecServiceFeeSum : '/'
                            }}</span>
                    </div>
                </div>
                <div style="display:flex; margin-top:10px;" v-if="info.InvoicingName == '两票制'">
                    <div class="sum-item" v-if="info.InvoicingName == '两票制'"><span class="sum-txt">运费不含税金额：</span><span
                            class="sum-num">{{
                                    totalData.WaybillNoTotalAmountSum ? '￥' +
                                        totalData.WaybillNoTotalAmountSum : '/'
                            }}</span></div>
                    <div class="sum-item"><span class="sum-txt">运费票面税额：</span><span class="sum-num">{{
                            totalData.WaybillTotalAmountTaxSum ? '￥' +
                                totalData.WaybillTotalAmountTaxSum : '/'
                    }}</span></div>

                    <div class="sum-item"><span class="sum-txt">技术服务费不含税金额：</span><span class="sum-num">{{
                            totalData.TecNoServiceFeeSum ? '￥' +
                                totalData.TecNoServiceFeeSum : '/'
                    }}</span>
                    </div>
                    <div class="sum-item"><span class="sum-txt">技术服务费票面税额：</span><span class="sum-num">{{
                            totalData.TecServiceFeeTaxSum
                                ? '￥' +
                                totalData.TecServiceFeeTaxSum : '/'
                    }}</span>
                    </div>
                </div>


            </div>
            <!-- 表格 -->
            <el-table :data="pagination.list" v-loading="pagination.loading"
                :header-cell-style="{ background: '#f0f0f0', color: '#666' }">
                <el-table-column align="center" type="index" prop="index" label="序号" width="50" fixed="left"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Rate" label="费率" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TotalAmount" label="开票总金额" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.TotalAmount ? '￥' + scope.row.TotalAmount : '/' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="150">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.ExpensesOfTaxation ? '￥' + scope.row.ExpensesOfTaxation : '/' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Freight" label="运费" width="150">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.Freight ? '￥' + scope.row.Freight : '/' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="WaybillTotalAmount" label="运费含税金额" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.WaybillTotalAmount ? '￥' + scope.row.WaybillTotalAmount : '/' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="WaybillNoTotalAmount" label="运费不含税金额" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.WaybillNoTotalAmount ? '￥' + scope.row.WaybillNoTotalAmount : '/' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="WaybillTotalAmountTax" label="运费/税费" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.WaybillTotalAmountTax ? '￥' + scope.row.WaybillTotalAmountTax : '/' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="TecServiceFee" label="技术服务费含税金额" width="150">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.TecServiceFee ? '￥' + scope.row.TecServiceFee : '/' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="TecNoServiceFee" label="技术服务费不含税金额" width="200">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.TecNoServiceFee ? '￥' + scope.row.TecNoServiceFee : '/' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="TecServiceFeeTax" label="技术服务费票面税额" width="150">
                    <template slot-scope="scope">
                        <div style="color:#FF8B17;">
                            {{ scope.row.TecServiceFeeTax ? '￥' + scope.row.TecServiceFeeTax : '/' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="StatusName" label="状态" width="160" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="">
                            {{ scope.row.StatusName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="StatusTime" label="状态变更时间" show-overflow-tooltip width="200">
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div style="display:flex;align-items: flex-start; margin-top: 10px">
                <el-pagination background class="pagination" @current-change="handleCurrentChange"
                    :current-page.sync="pagination.page" :page-size="pagination.pagesize"
                    layout="total, prev, pager, next, jumper" :total="pagination.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { GetInvoiceReportDetails, GetInvoiceReportDetailSum, DownloadInvoiceReportDetails } from '@/api/finance/statistics/index'
export default {
    data() {
        return {
            info: {
                CompanyName: '',
                BusinessTypeName: '',
                InvoicingName: ''
            },
            searchForm: {
                waybillStatus: '0', //运单状态
                time: ["", ""],
                InvoiceTaskId: '', //结算运单号
            },
            pagination: {
                page: 1,
                pagesize: 10,
                total: 0,
                loading: false,
                list: []
            },
            // 总计
            totalData: {
                ExpensesOfTaxationSum: '/',
                FreightSum: '/',
                TecNoServiceFeeSum: '/',
                TecServiceFeeSum: '/',
                TecServiceFeeTaxSum: '/',
                TotalAmountSum: '/',
                WaybillNoTotalAmountSum: '/',
                WaybillTotalAmountSum: '/',
                WaybillTotalAmountTaxSum: '/'
            },
            queryInfo: {}
        }
    },
    methods: {
        //获取统计明细列表
        getStatisticsList() {
            this.pagination.loading = true
            let params = {
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page,
                companyUserId: this.queryInfo.CompanyUserId,
                businessTypeCode: this.queryInfo.BusinessTypeCode,
                invoicingCode: this.queryInfo.InvoicingCode,
                startTime: this.searchForm.time ? this.searchForm.time[0] : '',
                endTime: this.searchForm.time ? this.searchForm.time[1] : '',
                waybillStatus: this.searchForm.waybillStatus,
                InvoiceTaskId: this.searchForm.InvoiceTaskId
            }
            GetInvoiceReportDetails(params).then((res) => {
                this.pagination.list = res.data.DataList.map((item, index) => {
                    item.index = ((this.pagination.page - 1) * this.pagination.pagesize) + index + 1
                    return item
                })
                if (this.pagination.list && this.pagination.list.length) {
                    this.info = {
                        CompanyName: this.pagination.list[0].CompanyName,
                        BusinessTypeName: this.pagination.list[0].BusinessTypeName,
                        InvoicingName: this.pagination.list[0].InvoicingName
                    }
                }
                this.pagination.total = Number(res.data.TotalCount)
            }).finally(() => {
                this.pagination.loading = false
            })
            this.getInvoiceReportSum()
        },
        //获取统计明细列表总计
        getInvoiceReportSum() {
            let params = {
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page,
                companyUserId: this.queryInfo.CompanyUserId,
                businessTypeCode: this.queryInfo.BusinessTypeCode,
                invoicingCode: this.queryInfo.InvoicingCode,
                startTime: this.searchForm.time ? this.searchForm.time[0] : '',
                endTime: this.searchForm.time ? this.searchForm.time[1] : '',
                waybillStatus: this.searchForm.waybillStatus,
                InvoiceTaskId: this.searchForm.InvoiceTaskId
            }
            GetInvoiceReportDetailSum(params).then((res) => {
                this.totalData = {
                    ExpensesOfTaxationSum: res.data.ExpensesOfTaxationSum,
                    FreightSum: res.data.FreightSum,
                    TecNoServiceFeeSum: res.data.TecNoServiceFeeSum,
                    TecServiceFeeSum: res.data.TecServiceFeeSum,
                    TecServiceFeeTaxSum: res.data.TecServiceFeeTaxSum,
                    TotalAmountSum: res.data.TotalAmountSum,
                    WaybillNoTotalAmountSum: res.data.WaybillNoTotalAmountSum,
                    WaybillTotalAmountSum: res.data.WaybillTotalAmountSum,
                    WaybillTotalAmountTaxSum: res.data.WaybillTotalAmountTaxSum
                }
            }).finally(() => {

            })
        },
        //搜索
        search() {
            this.pagination.loading = true
            this.pagination.page = 1
            this.getStatisticsList()
        },
        //分页控件页码change事件回调
        handleCurrentChange(e) {
            this.pagination.loading = true
            this.pagination.page = e
            this.getStatisticsList()
        },
        //导出excel
        exportExcel() {
            let params = {
                companyUserId: this.queryInfo.CompanyUserId,
                businessTypeCode: this.queryInfo.BusinessTypeCode,
                invoicingCode: this.queryInfo.InvoicingCode,
                startTime: this.searchForm.time ? this.searchForm.time[0] : '',
                endTime: this.searchForm.time ? this.searchForm.time[1] : '',
                waybillStatus: this.searchForm.waybillStatus,
                InvoiceTaskId: this.searchForm.InvoiceTaskId
            }
            DownloadInvoiceReportDetails(params).then(res => {
                window.location.href = res.data
            })
        },
    },
    created() {
        if (this.$route.query && this.$route.query.itemInfo) {
            this.queryInfo = JSON.parse(this.$route.query.itemInfo)
        }

        if (this.$route.query && this.$route.query.InvoiceTaskID) {
            this.searchForm.InvoiceTaskId = this.$route.query.InvoiceTaskID
        }
        this.getStatisticsList()
    }
}
</script>

<style lang="scss" scoped>
.statisticsInfo {
    .topbtns {
        padding-bottom: 10px;
        margin: 0px 0px 10px;
        border-bottom: 1px solid #eee;
    }

    .settleBox {
        margin: 15px 0px;
        padding: 10px 16px;
        background-color: #f0f0f0;
        color: #666;
    }

    .sum-item {
        color: rgb(255, 139, 23);
        margin-right: 15px;
        white-space: nowrap;

        .sum-txt {
            color: #333;
            font-weight: bold;
            text-align: right;
            width: 150px;
            display: inline-block;
        }

        .sum-num {
            display: inline-block;
            width: 200px;
        }
    }


}
</style>